@import "~chameleon/helpers/box";
@import "~chameleon/mixins";
@import "~chameleon/objects/grid";

@include grid-defaults($unmin: true);

@include classes("cols-spaced", $on: "base" "tablet-up" "mobile-up");
@include classes("cols-spaced-big");
@include classes(
  "cols-to-rows",
  $on: "base" "mobile" "fablet-down" "tablet" "tablet-up" "tablet-down"
    "desktop-up"
);

@include classes("widest", $on: "base");
@include classes(
  "col-1-2",
  $on: "base" "tablet" "fablet" "tablet-up" "tablet-down" "fablet-up"
    "mobile-and-fablet" "fablet-and-tablet" "desktop-up"
);
@include classes(
  "col-1-3",
  $on: "base" "fablet-down" "desktop-up" "tablet-up" "fablet-up"
);
@include classes("col-2-3", $on: "base" "fablet-down" "desktop-up");
@include classes("col-1-4", $on: "base" "fablet-down" "desktop-up");
@include classes("col-3-4", $on: "base" "fablet-down" "desktop-up");
@include classes("col-1-5", $on: "base" "desktop-up");
@include classes("col-2-5", $on: "base" "tablet-up" "desktop-up");
@include classes("col-3-5", $on: "base" "desktop-up");
@include classes("col-4-5");
@include classes("col-1-6", $on: "base" "tablet-up");
@include classes("col-5-6");

@include classes(
  "rows-spaced",
  $on: "base" "mobile" "desktop-up" "tablet-down" "fablet-down" "desktop-up"
);
@include classes("rows-spaced-small");
@include classes("rows-spaced-big", $on: "base" "tablet-up");
@include classes("cols-spaced-small", $on: "base" "fablet-up");

@include classes("cols-equal-height");
@include classes("cols-reversed", $on: "base" "tablet-up" "fablet-down");
@include classes("padded-rows-big", $on: "base");
@include classes("padded-rows-small", $on: "base");

// No JS
// -----

@include placeholders("col-1-2-if-no-js") {
  width: 50%;
}

.no-js {
  @include classes("col-1-2-if-no-js");
}

;@import "sass-embedded-legacy-load-done:96";