@import "~chameleon/helpers/box";
@import "~chameleon/objects/containers";

.container {
  width: 100%;
  @include container(1280px);
}

.container-guttered {
  width: calc(100% - 32px);
  @include container(1280px, 16px);
}

@include on("fablet-up") {
  .container-guttered-on-tablet-up {
    width: calc(100% - 32px);
    @include container(1280px, 16px);
  }
}

@include on("desktop-up") {
  .container-guttered-on-desktop-up {
    width: calc(100% - 32px);
    @include container(1280px, 16px);
  }

  .wide-layout {
    .container-guttered-on-desktop-up {
      width: calc(100% - 32px);
      @include container(1280px, 16px);
    }
  }
}

.container-small-guttered {
  width: calc(100% - 32px);
  @include container(600px, 16px);
}

;@import "sass-embedded-legacy-load-done:40";