@import "~wonderbly-css/base";

@mixin on-retina() {
  @media (min-resolution: 1.3dppx) {
    @content;
  }
}

@mixin line-break($size) {
  display: block;
  height: rem(2);
  border: 0;
  border-top: rem(1) solid get-colour("stone");
  margin: rem(10) 0;
  margin-left: $size;
  margin-right: $size;
}

@mixin unstyle-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

;@import "sass-embedded-legacy-load-done:777";